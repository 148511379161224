.agents-name-link {
    margin-right: auto;
}

.agentsflex {
    display: flex;
}

.agents-photo-size {
    width: 130px;
}

.agents-photo-container {
    margin-left: 50px;
}

@media only screen and (max-width: 400px) {
    .agentsflex {
        display: flex;
        flex-direction: column-reverse;
    }

    .agents-photo-size {
        width: 200px;
        margin: auto;
    }

    .agents-name-link {
        margin: auto;
    }

    .agents-photo-container {
        margin: auto;
    }
}