@media only screen and (min-width: 1650px) {
    .cardwidth {
        width: 50%;
    }
    .cardflex {
        display: flex;
    }
    h2 {
        font-size: 1.7vw;
    }
    .update-delete-price-container {
        display: flex;
        flex-direction: row;
        justify-content: end;
    }
  }  

@media only screen and (max-width: 1650px) {
    .cardwidth {
        width: 60%;
    }
    .cardflex {
        display: flex;
    }
    h2 {
        font-size: 2.2vw;
    }
    .update-delete-price-container {
        display: flex;
        flex-direction: row;
        justify-content: end;
    }
  }  

@media only screen and (max-width: 1100px) {
    .cardwidth {
        width: 75%
    }
    .cardflex {
        display: flex;
    }
    h2 {
        font-size: 3vw;
    }
  }

@media only screen and (max-width: 990px) {
    .cardwidth {
        width: 90%;
    }
    .cardflex {
        display: flex;
    }
    h2 {
        font-size: 3vw;
    }
  }

@media only screen and (max-width: 750px) {
    .cardflex {
        display: flex;
        flex-direction: column;
    }
    h2 {
        font-size: 4vw;
    }
    .update-delete-price-container {
        display: flex;
        flex-direction: column-reverse;
    }
  }

